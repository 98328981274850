<template>
  <div>
    <!--  Page Header  -->
    <base-page-header title="Brands" back-link="resource_center" />

    <!-- Page Breadcrumbs -->
    <div class="page-content-breadcrumbs">
      <router-link :to="{ name: 'resource_center' }">
        Resource Center
      </router-link>
      <span class="delimeter">
        /
      </span>
      <span>
        Brands
      </span>
    </div>

    <!-- Page Content -->
    <div class="page-content">
      <div class="row w-100 m-auto">
        <div class="col-md-4 ps-md-0">
          <router-link
            :to="{ name: 'brands_single', params: { name: 'cmc' } }"
            class="nav-link"
          >
            <div
              class="brands-item cmc d-flex flex-column justify-content-between"
            >
              <div class="brands-item__logo">
                <img src="@/assets/icons/logo-cmc-white.svg" alt="" />
              </div>
              <div class="brands-item__title">
                Clear Mortgage Capital, Inc.
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-4 mt-3 mt-md-0">
          <router-link
            :to="{ name: 'brands_single', params: { name: 'irefi' } }"
            class="nav-link"
          >
            <div
              class="brands-item irefi d-flex flex-column justify-content-between"
            >
              <div class="brands-item__logo">
                <img src="@/assets/icons/logo-irefi-white.svg" alt="" />
              </div>
              <div class="brands-item__title">
                iRefi.com
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-4 mt-3 mt-md-0 pe-md-0">
          <router-link
            :to="{ name: 'brands_single', params: { name: 'clear-realty' } }"
            class="nav-link"
          >
            <div
              class="brands-item realty d-flex flex-column justify-content-between"
            >
              <div class="brands-item__logo">
                <img src="@/assets/icons/logo-clearrealty-white.svg" alt="" />
              </div>
              <div class="brands-item__title">
                Clear Realty
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-4 mt-3 ps-md-0">
          <router-link
            :to="{ name: 'brands_single', params: { name: 'vision' } }"
            class="nav-link"
          >
            <div
              class="brands-item vision d-flex flex-column justify-content-between"
            >
              <div class="brands-item__logo">
                <img src="@/assets/icons/logo-vision-white.svg" alt="" />
              </div>
              <div class="brands-item__title">
                Vision
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-4 mt-3 ps-md-0">
          <router-link
            :to="{ name: 'brands_single', params: { name: 'black-ink' } }"
            class="nav-link"
          >
            <div
              class="brands-item black d-flex flex-column justify-content-between"
            >
              <div class="brands-item__logo">
                <img
                  src="@/assets/icons/logo-black-ink-vision.svg"
                  class="black-ink"
                  alt=""
                />
              </div>
              <div class="brands-item__title">
                Black Ink Financial
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasePageHeader from "../../../components/BasePageHeader";
export default {
  name: "Brands",
  components: { BasePageHeader }
};
</script>

<style lang="scss" scoped>
.page-header {
  padding-top: 12px;
}
.page-content {
  padding-top: 24px;

  .nav-link {
    width: 100%;
    padding: 0;
  }
}

.page-content-breadcrumbs {
  padding: 11px 24px;
  border-bottom: 1px solid #e6e6e6;

  a,
  span {
    display: inline-block;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
  }

  a {
    color: #4d4d4d;
    text-decoration: none;
  }

  span {
    margin-left: 9px;
    color: #006bf6;

    &.delimeter {
      color: #4d4d4d;
    }
  }
}

.brands-item {
  height: 178px;
  padding: 28px;
  border-radius: 4px;
  transition: all 1s ease;

  &.cmc {
    background: linear-gradient(225deg, #004255 0%, #0099c1 100%);
    background-position: right bottom;
    background-size: 202% 100%;

    &:hover {
      background-position: left bottom;
    }
  }

  &.irefi {
    background: linear-gradient(206.57deg, #e30010 0%, #ff7253 100%);
    background-position: right bottom;
    background-size: 200% 200%;

    &:hover {
      background-position: left bottom;
    }
  }

  &.realty {
    background: linear-gradient(225deg, #0322e2 0%, #5581fd 100%);
    background-position: right bottom;
    background-size: 202% 100%;

    &:hover {
      background-position: left bottom;
    }
  }

  &.vision {
    background: linear-gradient(225deg, #6dd97d 0%, #1e3eff 100%);
    background-position: right bottom;
    background-size: 202% 100%;

    &:hover {
      background-position: left bottom;
    }
  }

  &__title {
    color: #ffffff;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    font-weight: normal;
  }

  &.black {
    background: linear-gradient(225deg, #212121 0%, #686868 100%);
    background-position: right bottom;
    background-size: 202% 100%;

    &:hover {
      background-position: left bottom;
    }

    .black-ink {
      width: 40px;
      height: 40px;
    }
  }

  &__title {
    color: #ffffff;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    font-weight: normal;
  }
}
</style>
